import { $Elements, BrowserEvents, Classes, Elements } from '../global/constants';
import { ajax } from '../global/helpers';

;(function($) {

	$Elements.document.on(BrowserEvents.page.enter, () => {

		// ---------- Handle Form ----------

		function handleForm(form, params) {

			const formContent = form.find('.form-content');
			const formDone = form.find('.form-done');
			const formSuccess = formDone.find('.success');
			const formError = formDone.find('.error');
			const button = form.find('button[type=submit]');

			form.on('submit', e => e.preventDefault());

			form.parsley().on('form:submit', async() => {

				button.attr('disabled', true);
				Elements.LoadingBar.classList.add(Classes.loading);

				const formData = new FormData(form[0]);
				formData.append('action', params.action);
				formData.append('nonce', params.nonce);

				try {

					const response = await ajax({
						url: params.url,
						type: 'POST',
						dataType: 'json',
						data: formData,
						processData: false,
						contentType: false,
					});

					formSuccess.removeClass(Classes.hidden);
				}
				catch(e) {
					formError.removeClass(Classes.hidden);
				}

				button.attr('disabled', false);
				Elements.LoadingBar.classList.remove(Classes.loading);
				formContent.addClass(Classes.hidden);
				formDone.removeClass(Classes.hidden);

				return false;
			});
		}

		// ---------- Contact Form ----------

		if(typeof contact_form_params !== 'undefined') {

			$('form.contact-form').each(function() {
				handleForm($(this), contact_form_params);
			});
		}

	});

}(jQuery));
