import StoreLocator from 'store-locator';

import { $Elements, BrowserEvents } from './constants';

;(function($) {

	$Elements.document.on(BrowserEvents.page.enter, () => {

		// ---------- Simple Map ----------

		$('.simple-map').each(function() {

			let marker = this.dataset.marker;
			marker = (typeof marker === 'string') ? JSON.parse(marker) : marker;

			const map = new StoreLocator({
				stores: marker,
				selectors: {
					map: this.attributes.id.value,
				},
				map: {
					options: {
						maxZoom: 15,
					},
					markers: {
						icon: feature => L.icon({
							iconUrl: feature.properties.icon,
							iconSize: [88, 120],
							iconAnchor: [44, 120],
							popupAnchor: [0, -120],
						}),
					}
				}
			});
		});

	});

}(jQuery));
