import { $Elements, BrowserEvents } from './constants';

;(function($) {

	$Elements.document.on(BrowserEvents.page.enter, () => {

		// ---------- Sections ----------

	});

}(jQuery));
