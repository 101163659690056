import { wait } from '../global/helpers';
import { $Elements, BrowserEvents, Classes, Elements, TransitionDurations, TransitionnableLinks } from '../global/constants';

;(function($) {

	// ---------- Handle In ----------

	$Elements.document.ready(() => $Elements.document.trigger(BrowserEvents.page.enter));

	$Elements.window.on('load', transitionIn);

	$Elements.window.on('pageshow', e => (e.originalEvent.persisted) ? transitionIn() : null);

	// ---------- Hnadle Out ----------

	$Elements.window.on('unload', () => null);

	$(TransitionnableLinks).on('click', async e => {

		e.preventDefault();

		await transitionOut();

		if(e.which === 2 || e.metaKey || e.shiftKey || navigator.platform.toUpperCase().indexOf('WIN') !== -1 && e.ctrlKey) {
			window.open(e.currentTarget.href, '_blank');
		}
		else {
			window.location.href = e.currentTarget.href;
		}
	});

	// ---------- Transitions ----------

	function transitionIn() {
		Elements.MainWrapper.classList.remove(Classes.loading, Classes.initialLoad);
		Elements.LoadingBar.classList.remove(Classes.loading);
		Elements.LoadingOverlay.classList.remove(Classes.loading);
	}

	async function transitionOut() {

		$Elements.document.trigger(BrowserEvents.page.leave);

		Elements.MainWrapper.classList.add(Classes.loading);
		Elements.LoadingBar.classList.add(Classes.loading);
		Elements.LoadingOverlay.classList.add(Classes.loading);

		await wait(TransitionDurations.page);
	}

}(jQuery));
