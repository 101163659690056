import Cookies from 'js-cookie';
import { Autoplay, EffectFade, Navigation, Swiper } from 'swiper';

import { $Elements, BrowserEvents, TransitionDurations } from './constants';

Swiper.use([Autoplay, EffectFade, Navigation]);

;(function($) {

	$Elements.document.on(BrowserEvents.page.enter, () => {

		// ---------- Disclaimer ----------

		$('#disclaimer').on('closed.bs.alert', function() {

			Cookies.set('tc-disclaimer', true, {
				expires: 365,
				path: '/',
			});

			$Elements.body.removeClass('disclaimer-open');
		});

		// ---------- Cookies ----------

		$('#cookies-law').on('closed.bs.alert', function() {

			Cookies.set('tc-cookies', true, {
				expires: 365,
				path: '/',
			});
		});

		// ---------- Masonry ----------

		$('.content-section .masonry').each(function() {
			const self = $(this);

			self.isotope({
				masonry: {
					percentPosition: true
				},
				itemSelector: ".single-column"
			})
		});

		// ---------- Carousel ----------

		$('.content-section .carousel').each(function() {

			if(!this.swiper) {

				const swiper = new Swiper(this, {
					speed: TransitionDurations.default,
					loop: true,
					observer: true,
					pagination: {
						el: this.querySelector('.swiper-pagination'),
					},
					navigation: {
						nextEl: this.querySelector('.swiper-button-next'),
						prevEl: this.querySelector('.swiper-button-prev'),
					},
				});
			}
		});

		// ---------- Slideshow ----------

		$('.parallax-section .slideshow').each(function() {

			var autoplay = false;
			if($(this).closest('.parallax-section').hasClass('first-section')) {
				autoplay = true;
			}

			if(!this.swiper) {

				const swiper = new Swiper(this, {
					speed: TransitionDurations.default,
					loop: true,
					observer: true,
					autoplay: autoplay,
					effect: 'fade',
					fadeEffect: {
						crossFade: true
					},
					pagination: {
						el: this.querySelector('.swiper-pagination'),
					},
					navigation: {
						nextEl: this.querySelector('.swiper-button-next'),
						prevEl: this.querySelector('.swiper-button-prev'),
					},
				});
			}
		});

	});

}(jQuery));
